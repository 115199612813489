import { useEffect, useState } from 'react';
import axios from 'axios';
import AdoptDuckForm from './AdoptDuckForm';
import FoundDuckForm from './FoundDuckForm';
import RehideDuck from './RehideDuck';
import { Resturl } from '../components/global';
import { DuckCard } from '../components/duck-card/DuckCard';
import { LoadDuckSkeleton } from '../components/load-duck/LoadDuckSkeleton';
import { FinderCard } from '../components/finders/finder-card/FinderCard';
function LoadDuck() {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const params = new URLSearchParams(window.location.search);
  const isEmailLink = params.get('isEmailLink');
  useEffect(() => {
    function getUrlParams() {
      const search = window.location.search.substring(1);
      const params = {};

      search.split('&').forEach(param => {
        const [key, value] = param.split('=');
        params[key] = value;
      });

      return params;
    }


    const params = getUrlParams();
    const { guid, duckType } = params || {};

    if (guid && duckType) {
      const fetchData = async () => {
        try {
          const endpoint = `/tag?guid=${guid}&duckType=${duckType}`;

          const fullUrl = Resturl + endpoint;
          axios
            .get(fullUrl)
            .then(response => { setData(response.data); })
            .catch(err => { console.error('Error fetching data:', err); })
            .finally(() => { setLoading(false); });

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    } else {
      setLoading(false);
    }
  }, []);

  return (

    loading ? (<LoadDuckSkeleton />) :

      <div style={{ fontFamily: 'Poppins' }}> {/* Add responsive container */}
        {
          data?.duck ?
            (
              <div>
                {!isEmailLink && data.duck && data.status === "2" && 
              <h1>YOU FOUND ME!</h1>
                }
               
                <DuckCard data={data} />
                {!isEmailLink && data.duck && data.status === "3" && <RehideDuck guid={data.guid} />}
                <div>{data.duck && data.status === "0" && <AdoptDuckForm guid={data.guid} DuckName={data.duck.name} />}</div>
                <div>{!isEmailLink && data.duck && data.status === "2" && <FoundDuckForm guid={data.guid} />}</div>
                {
                  (isEmailLink || (data.duck && data.status === "3")) &&
                  <div>
                    <div className="finder-card-group">
                      { data.users.map(user => <FinderCard user={user} key={user.id} />) }
                    </div>

                    {!isEmailLink && data.duck && data.status === "3" && <RehideDuck guid={data.guid} />}

                  </div>
                }
              </div>
            ) : <DuckCard />
        }

      </div>
  );
}
export default LoadDuck;