

// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import footerLogo from '../../white_logo.png';
import './Footer.css';

export class Footer extends Component {
    render () {
        return (
            <footer className="app-footer">
                <img src={footerLogo} alt="Logo"    style={{
        height: '100%',
        margin: '1px'
       
      }} />
            </footer>
        );
    }
}