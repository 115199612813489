

// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import './FinderCard.css';
import ImageComponent from '../../ImageDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { boolify } from '../../../helpers/common';
import AwaitingModApproval from '../../../awaiting_mod_approval.png'

export function FinderCard (props) {
    const { user } = props || {};
    const [beat, setBeat] = useState(true);
    const initUserType = () => {
        switch (user.type) {
            case 'Hide': return 'HIDER';
            case 'Find': return 'FINDER';
            default: return '';
        }
    }
    const userType = initUserType();
    const isApproved = boolify(user.userSelfieApproved);
    setTimeout(() => {
        setBeat(false);
    }, 5000);
    return (
        user &&
        <Card className="finder-card">
            <div className="ac-container">
                {
                    user.type === 'Hide' && 
                    <div style={{ position: 'absolute', top: '-20px', left: '-15px', zIndex: 4, display: 'flex', alignContent: 'center', transform: 'rotateY(0deg) rotate(-45deg)' }}>
                        <FontAwesomeIcon icon={faCertificate} style={{ fontSize: '60px', color: '#ed6842' }} beat={beat}></FontAwesomeIcon>
                        <span style={{ position: 'absolute', color: '#333', top: '50%', left: '50%', fontSize: '12px', transform: 'translate(-50%, -50%)' }}>Hider</span>
                    </div>
                }
                {
                    user.selfie && isApproved ? <ImageComponent base64String={user.selfie} /> :
                    user.selfie && !isApproved && <img src={AwaitingModApproval} alt=""/>
                }
                {
                    user.selfie && isApproved ? <div className="ac-card-background" style={{ backgroundImage: `url("data:image/png;base64,${user.selfie}")` }}></div> :
                    user.selfie && !isApproved && <div className="ac-card-background" style={{ backgroundImage: `url("${AwaitingModApproval}")` }}></div>
                }
                
            </div>
            <Card.Body>
                <Card.Title>{user.name}</Card.Title>
                <Card.Subtitle className="text-muted">{user.location}</Card.Subtitle>
            </Card.Body>
            {
                user.bio &&
                <Card.Footer className="about-footer">
                    <h5>ABOUT{userType && ` ${userType}`}</h5>
                    {
                        isApproved ?
                        <Card.Text>{user.bio}</Card.Text> :
                        <Card.Text className='text-muted'><small>Awaiting MOD approval...</small></Card.Text>
                    }
                </Card.Footer>
            }
            <Card.Footer>
                {moment(user.statusDate, 'YYYY-MM-DDThh:mm:ss').fromNow()}
            </Card.Footer>
        </Card>
    );
}