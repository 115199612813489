import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';

function ConfettiOverlay() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [isConfettiActive, setIsConfettiActive] = useState(true);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsConfettiActive(false);
    }, 15000);

    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {isConfettiActive && (
        <Confetti
          width={width}
          height={height}
        />
      )}
    </>
  );
}

export default ConfettiOverlay;
