

// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { Button, ButtonGroup, Card } from 'react-bootstrap';
import './ApproveCard.css';
import ImageComponent from '../../ImageDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Resturl } from '../../global';
import axios from 'axios';

export function ApproveCard (props) {
    const { item, actionEvent } = props || {};
    const onRejectClick = () => {
        alert(`TODO: Reject ${item.name}`);
        actionEvent();
    }
    const onApproveClick = async () => {
        const endpoint = `/approve`;

        const fullUrl = Resturl + endpoint;
        axios.get(fullUrl+"?Id="+item.id+"&state=true")
            .then(() => { actionEvent(); })
            .catch(err => console.error('Error calling service:', err)); 
    }
    return (
        item &&
        <Card>
            <div className="ac-container">
                {item.selfie &&
                    <ImageComponent base64String={item.selfie} />
                }
                <div className="ac-card-background" style={{ backgroundImage: `url("data:image/png;base64,${item.selfie}")` }}>
                </div>
            </div>
            <Card.Body>
                <Card.Title>{item.name}</Card.Title>
                <Card.Subtitle className="text-muted">{item.location}</Card.Subtitle>
                <Card.Text>
                    {item.bio}
                </Card.Text>
            </Card.Body>
            <Card.Footer>
                <ButtonGroup aria-label="Approval Options" className="approval-buttons">
                    <Button variant="light" onClick={onRejectClick}><FontAwesomeIcon icon={faTrashAlt}/></Button>
                    <Button variant="light" onClick={onApproveClick}><FontAwesomeIcon icon={faCheck}/></Button>
                </ButtonGroup>
            </Card.Footer>
        </Card>
    );
}