import React, { useState, useRef } from 'react';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import { Container, Form, Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Resturl } from '../components/global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
const MyForm = ({ guid, DuckName }) => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    bio: '',
    guid: guid,
    status: '1',
    selfie: ''
  });
  const [selfieTaken, setSelfieTaken] = useState(false);
  const [file, setFile] = useState(null);
  const [outputText, setOutputText] = useState(
    "Complete the following form and feel free to upload a selfie with your Duck (though it's optional!). As soon as your Duck is found, we'll send you an email about the adventurous finder. You'll even get to see their selfie taken with your Duck. Let the fun begin!"
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);

  const handleInvalid = (event) => {
    event.target.setCustomValidity("Your Email (We'll only use it to notify you when this duck is found. Your email will always remain private, we promise not to share it.)");
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const fileInputRef = useRef(null);

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value
    });

    if (event.target.name === "email") {
      event.target.setCustomValidity('');
    }
  };

  const handleFileChange = async (event) => {
    setSelfieTaken(true);
    const originalFile = event.target.files[0];
    try {
      Resizer.imageFileResizer(
        originalFile,
        300,
        300,
        'JPEG',
        100,
        0,
        (uri) => {
          const blob = new Blob([uri], { type: 'image/jpeg' });
          setFile(blob);
        },
        'blob'
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleStartClick = () => {
    setHasStarted(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure you want to save? This action cannot be undone.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const formData = new FormData();
            Object.keys(form).forEach((key) => formData.append(key, form[key]));
            formData.append('selfie', file);

            axios
              .post(`${Resturl}/adopt`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then((response) => {
                console.log(response);
                setOutputText(
                  "Your Duck is now primed for its hide-and-seek adventure! Enjoy the hiding process! The next time your Duck's QR code is scanned, we will encourage the lucky finder to enter their details. Once they do, we'll notify you via email. Happy hiding!"
                );
                setIsSubmitted(true);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  return (
    <Container className="py-1">
      {!hasStarted || isSubmitted ? (
        <div>
          <p style={{ fontSize: '12px' }}>{outputText}</p>
          {!isSubmitted && <div className="text-center">
              <Button variant="primary" onClick={handleStartClick}>Get Started</Button>
            </div>}
        </div>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formname" className="mb-3">
            <Form.Control size="sm"  placeholder="What is your name?" type="text" name="name" value={form.name} onChange={handleChange} required />
          </Form.Group>
          <Form.Group controlId="formBasicEmail" className="mb-3">
            <Form.Control  size="sm" placeholder={`Email so we can alert you when ${DuckName} is found!`} type="email" name="email" value={form.email} onInvalid={handleInvalid} onChange={handleChange} required />
            <Form.Text className="text-muted"> *We'll never share your email with anyone else.</Form.Text>
          </Form.Group>
          <Form.Group controlId="formLocation" className="mb-3">
            <Form.Control size="sm"  type="text" placeholder="Where are your from? (Optional)" name="location" value={form.location} onChange={handleChange} />
          </Form.Group>
                    <Form.Group controlId="formBasicBio" className="mb-3">
            <Form.Control size="sm"  as="textarea" placeholder="Tell us a little bit about yourself" name="bio" value={form.bio} onChange={handleChange} />
          </Form.Group>
       {/* <Form.Group controlId="formFacebook" className="mb-3">
            <Form.Control type="text" placeholder="Share your Facebook profile (Optional)" name="faceBook" value={form.faceBook} onChange={handleChange} />
          </Form.Group>
          <Form.Group controlId="formInstagram" className="mb-3">
            <Form.Control type="text" placeholder="Share your Instagram profile (Optional)" name="instagram" value={form.instagram} onChange={handleChange} />
          </Form.Group>
          <Form.Group controlId="formHashtag" className="mb-3">
            <Form.Control type="text" placeholder="Any Hashtags? (Optional)" name="hashTag" value={form.hashTag} onChange={handleChange} />
      </Form.Group> */} 
          <p></p>
          <Form.Group controlId="formBasicFile">
            <Form.Label><p style={{ fontSize: '12px' }}>Upload a selfie with {DuckName} for the finder to see!</p></Form.Label>
            <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
            <p>{selfieTaken && <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: 'green' }}/> } <Button variant="primary" onClick={handleButtonClick}>Take Selfie</Button></p>
          </Form.Group>
          {!isSubmitted && <p><Button variant="primary" type="submit" className="mb-3">Save</Button></p>}
        </Form>
      )}
    </Container>
  );
};

export default MyForm;
