import GoogleAnalytics from '../components/GoogleAnalytics';
import { Welcome } from '../components/welcome/Welcome';
import { HowItWorks } from '../components/how-it-works/HowItWorks';
const DuckyQuestWebsite = () => {
  return (
 
    <div>
          <GoogleAnalytics trackingId="G-TBQZGXXZCL" />
          <Welcome />
          <HowItWorks />
    </div>
  );
};

export default DuckyQuestWebsite;
