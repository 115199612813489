

// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import logo from '../../logo.png';
import './Header.css';

export class Header extends Component {
    render () {
        return (
            <header className="app-header">
                <div>
                    <img src={logo} alt="Logo" className="logo" />
                </div>
            </header>
        );
    }
}