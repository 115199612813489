const boolMap = {
    true: true,
    yes: true,
    t: true,
    y: true,
    1: true,
};

const boolify = (s) => {
    if(typeof s === 'string') {
        return boolMap[s.toLowerCase()] || false;
    }
    return s ? true : false;
}

export { boolify };