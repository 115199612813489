// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import './Welcome.css';
import { Button } from 'react-bootstrap';

export class Welcome extends Component {
    render () {
        return (
            <div className="welcome-container">
                <h1>Welcome to DuckyQuest!</h1>
                <p>
                    DuckyQuest is a fun adventure game where rubber ducks are hidden and found by players around the world. 
                    Each rubber duck is equipped with a unique QR code that links to a virtual record of its journey.
                </p>
                <Button variant="link" href="https://www.etsy.com/shop/DuckyQuest">START YOUR QUEST TODAY!</Button>
            </div>
        );
    }
}