// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import './HowItWorks.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassLocation, faPaperclip, faQrcode } from '@fortawesome/free-solid-svg-icons';

const steps = [
    { cta: "Attach", description: "Attach your DuckyQuest QR tag to your supplied duck.", icon: faPaperclip},
    { cta: "Scan", description: "Adopt the duck by scanning the QR code and filling out your information.", icon: faQrcode },
    { cta: "Hide", description: "Hide the duck in a new location and get alerted when another adventurer finds it.", icon: faMagnifyingGlassLocation }
]

export class HowItWorks extends Component {
    render () {
        return (
            <div className="hiw-container">
                <h2>How It Works</h2>
                <div className="hiw-cards">
                    {
                        steps.map((step, idx) => 
                            <Card>
                                <Card.Title>{idx + 1}</Card.Title>
                                <Card.Body>
                                    <FontAwesomeIcon icon={step.icon}/>
                                    <Card.Text>
                                        {step.cta}
                                    </Card.Text>
                                    {step.description}
                                </Card.Body>
                            </Card>
                        )
                    }
                </div>
                <p>
                    Once you've completed these steps, you become a part of the duck's journey and can follow its 
                    adventures on the DuckyQuest website. The finder also has the option to hide the duck again for 
                    someone else to find, and you will always receive alerts and selfies of the other adventures!
                </p>
            </div>
        );
    }
}