

// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import './DuckCard.css';
import DefaultDuck from '../../default_duck.png';
import CryDuck from '../../cry_duck.png';

export function DuckCard (props) {
    const { data } = props || {};
    const { duck = { name: 'Duck Not Found', birthplace: 'Invalid GUID'} } = data || {};
    const timesFound = Math.max(data?.users.length - 1, 0) || 0;
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        (
            <div className="welcome-container">
                <div className="duck-card-container">
                    <Card>
                        <div className="duck-card-image-container">
                        <div >
                            <Card.Img variant="top" src={data ? DefaultDuck : CryDuck} />
                        </div>
                        </div>
                        <Card.Body>
                            <Card.Title > {duck?.name}</Card.Title>
                            { duck?.birthplace && <Card.Subtitle> {duck?.birthplace}</Card.Subtitle>}

                            {
                                data && 
                                <div className='duck-stats'>
                                    <div>
                                        <div>{duck?.age || 0}</div>
                                        <div>AGE</div>
                                    </div>
                                    <div>
                                        <div>{timesFound}</div>
                                        <div>FOUND</div>
                                    </div>
                                </div>
                            }
                            {duck?.shortBio && <Card.Text style={{ fontSize: '14px' , lineHeight: '1'  }}>{ duck?.shortBio }<span onClick={handleShow} style={{color: 'blue', cursor: 'pointer'}}>
        [more]
      </span></Card.Text>}
                            
                        </Card.Body>
                    </Card>
                </div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{duck?.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {duck?.bio}
                    </Modal.Body>
                </Modal>
            </div>
        )
    );
}