import React, { useState } from 'react';
import axios from 'axios';
import {  Button } from 'react-bootstrap';
import { Resturl } from '../components/global';
class MyComponent extends React.Component {
  state = {
    message: '',
    showButton:true
  };
  getData = () => {
    
    const endpoint = `/rehide?guid=${encodeURIComponent(this.props.guid)}`;

          const fullUrl = Resturl + endpoint;
       
    
    axios.get(fullUrl)
      .then(response => {
        this.setState({ message: 'Your Duck is now primed for its hide-and-seek adventure! Enjoy the hiding process! The next time your Ducks QR code is scanned, we will encourage the lucky finder to enter their details. Once they do, we will notify you via email. Happy hiding!' });
        this.setState({ showButton: false });
       
        console.log(response.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  render() {
    return (
    <div>
  <p>{this.state.message} {this.state.showButton} </p>
    <p>{ this.state.showButton &&  <Button variant="primary" onClick={this.getData}>ReHide Your Duck</Button>}</p>
    
    </div>
    );
  }
}

export default MyComponent;
