import Skeleton from "react-loading-skeleton";

export function DuckCardSkeleton () {
    return (
        <div className="welcome-container">
            <div className="duck-card-container" style={{ minWidth: '350px' }}>
                <div className="card">
                    <div className="duck-card-image-container">
                        <div>
                            <Skeleton height="68px" circle={true}/>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="card-title h5"><Skeleton height="24px" width="240px" /></div>
                        <div className="card-subtitle h6"><Skeleton height="19px" width="180px" /></div>
                        <div className="duck-stats">
                            <Skeleton height="50px" width="50px" />
                            <Skeleton height="50px" width="50px" />
                        </div>
                        <p className="card-text"><Skeleton height="84px" width="300px" /></p>
                        <Skeleton height="38px" width="114px" />
                    </div>
                </div>
            </div>
        </div>
    )
}