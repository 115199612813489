import { useEffect, useState } from 'react';
import axios from 'axios';
import { Resturl } from '../components/global';
import { ApproveCard } from '../components/finders/approve-card/ApproveCard';
import { FindersSkeleton } from '../components/finders/FindersSkeleton';


function MyComponent() {
  const [data, setData] = useState([]);
  const [loadingApprovals, setLoading] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setLoading(true);
    try {
      const endpoint = `/unapproved`;

      const fullUrl = Resturl + endpoint;
      const response = await axios.get(fullUrl);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const onActionEvent = () => {
    fetchData();
  }

  return (
    loadingApprovals ?
    <FindersSkeleton /> :
    <div className="finder-card-group">
      {data.map((item) => (
        <ApproveCard item={item} actionEvent={onActionEvent}/>
      ))}
    </div>
  );
}

export default MyComponent;
