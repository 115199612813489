import React, { useState ,useRef} from 'react';
import axios from 'axios';
import CF from '../components/Confetti';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Resizer from 'react-image-file-resizer';
import { Resturl } from '../components/global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
const MyForm = ({ guid }) => {
  const [file, setFile] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const isEmailLink = params.get('isEmailLink');
  
  const date = new Date();
  const [selfieTaken, setSelfieTaken] = useState(false);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero indexed, hence incrementing by 1
  const day = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  const [form, setForm] = useState({ name: '', foundLocation: '', foundDateTime: formattedDate, bio: '', foundSelfie: null, guid: guid, status: '1' });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasStarted, setHasStarted] = useState(false); // Add this state
  const handleButtonClick = () => {
    
    fileInputRef.current.click();
  };
  
  const handleInvalid = (event) => {
    event.target.setCustomValidity('Your Email (We\'ll only use it to notify you when this duck is found if you choose to rehide it. Your email will always remain private, we promise not to share it.)');
  };
  const handleStartClick = () => {
    setHasStarted(true);
  };
  const fileInputRef = useRef(null);
  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value
    });
    if (event.target.name === "email") {
      event.target.setCustomValidity('');
    }
  };
  const handleFileChange = async (event) => {
    setSelfieTaken(true);
    const originalFile = event.target.files[0];
    try {
      Resizer.imageFileResizer(
        originalFile,
        300,
        300,
        'JPEG',
        100,
        0,
        uri => {
          // Create a new Blob object from the resized image URI
          const blob = new Blob([uri], { type: 'image/jpeg' });
          setFile(blob);
        },
        'blob'
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure you want to mark this duck as found? This action cannot be undone.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let formData = new FormData();

            Object.keys(form).forEach((key) => {
              formData.append(key, form[key]);
              formData.append('foundSelfie', file);
            });

            axios.post(`${Resturl}/found`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
              .then((response) => {
                console.log(response);
                setIsSubmitted(true); 
                window.location.reload();
                
              })
              .catch((error) => {
                console.error(error);
              });
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <Container className="py-3">
      <CF />
      {!hasStarted || isSubmitted ? (
        <div>
          {
           // !isEmailLink && 
           // <Alert key="primary" variant="primary">
            //  <h1>YOU FOUND ME!</h1>
           // </Alert>
          }
          <p style={{ fontSize: '14px' }}>
            <span style={{ color: 'var(--bs-heading-color)' }}>Congratulations</span> on finding me! You're part of the DuckyQuest adventure now. Take a moment to fill 
            out the form, share your email address, and if you wish, upload a selfie with your new feathered 
            friend. Your details and photo will connect you with the previous adventurers, adding another exciting 
            chapter to our duck's journey. Want to continue the fun? Rehide me for the next person to discover and 
            choose to receive updates on the duck's future adventures!
          </p>
          <p>Let's keep the DuckyQuest going!</p>
          {!isSubmitted &&  <p><Button variant="primary" onClick={handleStartClick}>Get Started</Button></p>}
        </div>
      ) : (
<Form onSubmit={handleSubmit}>
  <Form.Group controlId="formBasicName" className="mb-3">
     <Form.Control placeholder="What is your name?"  type="text" name="name" value={form.name} onChange={handleChange} required />
  </Form.Group>

  <Form.Group controlId="formBasicLocation" className="mb-3">
      <Form.Control placeholder="Where did you find me?" type="text" name="foundLocation" value={form.foundLocation} onChange={handleChange} required />
  </Form.Group>

  <Form.Group controlId="formBasicDate" className="mb-3">
      <Form.Control type="datetime-local" name="foundDateTime" value={form.foundDateTime} onChange={handleChange} required />
  </Form.Group>

  <Form.Group controlId="formBasicBio" className="mb-3">
      <Form.Control as="textarea" placeholder="Tell us a bit about yourself." name="bio" value={form.bio} onChange={handleChange} />
  </Form.Group>

  <Form.Group controlId="formBasicEmail" className="mb-3">
    <Form.Control type="email" placeholder="Email (We wont share it!)" name="email" value={form.email} onChange={handleChange}  onInvalid={handleInvalid} required />
   </Form.Group>
  {/*
   <Form.Group controlId="formFacebook" className="mb-3">
            <Form.Control type="text" placeholder="Share your Facebook profile (Optional)" name="faceBook" value={form.faceBook} onChange={handleChange} />
          </Form.Group>
          <Form.Group controlId="formInstagram" className="mb-3">
            <Form.Control type="text" placeholder="Share your Instagram profile (Optional)" name="instagram" value={form.instagram} onChange={handleChange} />
          </Form.Group>
          <Form.Group controlId="formHashtag" className="mb-3">
            <Form.Control type="text" placeholder="Any Hashtags? (Optional)" name="hashTag" value={form.hashTag} onChange={handleChange} />
          </Form.Group>
      */}
   <Form.Group controlId="formBasicFile">
        <Form.Label><p style={{ fontSize: '12px' }}>Upload a selfie with your new Duck!</p></Form.Label>
       {/* <Form.Control type="file" accept="image/*" onChange={handleFileChange} /> */}
        <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      
      <p>{selfieTaken && <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: 'green' }}/>} <Button variant="primary" onClick={handleButtonClick}>Take Selfie</Button></p>
      </Form.Group>
      {!isSubmitted && <p><Button variant="primary" type="submit" className="mb-3">Mark your Duck as Found!</Button></p>}
</Form>)}
    </Container>
  );
};

export default MyForm;

