
import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ApproveForm from './pages/ApproveSelfiesBios';
import DuckyQuest from './pages/DuckyQuest';
import LoadDuck from './pages/loadDuck';
import { Header } from './components/header/Header';
import { Footer } from './components/footer/Footer';

function App() {
  const url = window.location.href;
  const isApprovePage = url.includes('/approve');
  const isDuckPage = url.includes('guid');

  return (
    <div>
      <Header />
      {
        isApprovePage ? <ApproveForm /> :
        isDuckPage ? <LoadDuck /> :
        <DuckyQuest />
      }
      <Footer />
    </div>
  );
}

export default App;

