import { Button, ButtonGroup, Card } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import './approve-card/ApproveCard.css';
import './FindersSkeleton.css';

export function FindersSkeleton() {
    return (
        <div className="approvals-skeleton">
            {
                [1, 2, 3, 4].map(() => 
                    (
                        <Card>
                            <Card.Body>
                                <SkeletonTheme>
                                    <p>
                                        <Skeleton height="300px" width="225px"/>
                                    </p>
                                </SkeletonTheme>
                                <Card.Title>
                                    <SkeletonTheme>
                                        <Skeleton />
                                    </SkeletonTheme>
                                </Card.Title>
                                <Card.Text>
                                    <SkeletonTheme>
                                        <Skeleton />
                                    </SkeletonTheme>
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <ButtonGroup aria-label="Approval Options" className="approval-buttons">
                                    <Button variant="light">&nbsp;</Button>
                                    <Button variant="light">&nbsp;</Button>
                                </ButtonGroup>
                            </Card.Footer>
                        </Card>
                    )
                )
            }

        </div>
    )
}